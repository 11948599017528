import React, { Component } from 'react';
import { Link } from "gatsby"

import './HButtonGradient.scss';

class HButtonGradient extends Component {
    render() {
        const {
            buttonText = '',
            className = '',
            onClick = () => { },
            htmlType = 'button',
            href = "",
            type = "",
            target = false,
            disabled = false
        } = this.props || {};
        return htmlType === 'link' ? <Link
            to={href}
            target={target ? "_blank" : "_self"}
            className={`hummingbird-grad-btn ${className}`}
            onClick={onClick}
            // hadscroll={true}
        >
            {buttonText}
        </Link>
            : <button
                className={`hummingbird-grad-btn ${className}`}
                onClick={onClick}
                type={type ? type : "actionType"}
                disabled={disabled}
            >
                {buttonText}
            </button>
    }
}

export default (props) => {
    const { keyWord = '' } = props || {}
    return <HButtonGradient
        {...props}
        buttonText={keyWord}
    />
}
