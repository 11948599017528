// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-customer-js": () => import("./../../../src/pages/customer.js" /* webpackChunkName: "component---src-pages-customer-js" */),
  "component---src-pages-driver-js": () => import("./../../../src/pages/driver.js" /* webpackChunkName: "component---src-pages-driver-js" */),
  "component---src-pages-founders-fund-calculator-js": () => import("./../../../src/pages/founder's-fund-calculator.js" /* webpackChunkName: "component---src-pages-founders-fund-calculator-js" */),
  "component---src-pages-founders-fund-js": () => import("./../../../src/pages/founder's-fund.js" /* webpackChunkName: "component---src-pages-founders-fund-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/Blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

