import React, { useContext } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap'
import { Link, navigate } from "gatsby"
import { SupportPhone, SocialLinks } from '../../constants/commonData'
import { getEmail } from '../../constants/commonFunctions'
import CookieConsent from "react-cookie-consent";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import CountryContext from "../../context/CountryContext"
import FlagIconFactory from 'react-flag-icon-css';
import SelectCountry from '../../components/SelectCountry/SelectCountry'

import './footer.scss'
const FlagIcon = FlagIconFactory(React, { useCssModules: false });
function Footer(props) {
  const Context = useContext(CountryContext);
  const CountryCode = Context.country
  const languageCode = props.intl.locale || "en"
  let infoUS = {
    name: 'myQcar',
    address: 'Milwaukee, Wisconsin',
    phone: '+14145520922',
    fb: 'https://www.facebook.com/GOJO-Wisconsin-102407331986708',
    ins: 'https://www.instagram.com/gojowisconsin/',
    ytb: 'https://www.youtube.com/channel/UCn1xczr34mwYufA5ADJPg-g'
  }

  if (CountryCode === 'us' && Context.fleetId === 'gojotexas') {
    infoUS = {
      name: 'ONERIDE',
      address: 'Dallas, Texas 75254',
      phone: '+18446917433',
      fb: 'https://www.facebook.com/GOJO-Texas-111853324338222',
      ins: 'https://www.instagram.com/gojotexas/',
      ytb: 'https://www.youtube.com/c/gojoglobal'
    }
  }
  let email = getEmail(CountryCode, Context.fleetId);
  const phone = SupportPhone[CountryCode];
  return (
    <footer>
      <div className="h-container">
        <Row>
          <Col xs={12} md={4}>
            <div className="footer_left">
              <Link to={`/${languageCode}/`}>
                {
                  (CountryCode != 'my' && CountryCode != 'in') &&
                  <img
                    className="footer img-logo"
                    src={`../../../images/logo_big_2.png`}
                  />
                }
                {
                  (CountryCode == 'my' || CountryCode == 'in') &&
                  <img
                    className="footer img-logo"
                    src={`../../../images/logo_big.png`}
                  />
                }
              </Link>
              {
                CountryCode != 'gb'
                && CountryCode != 'us'
                && CountryCode != 'vn'
                && CountryCode != 'my'
                && CountryCode != 'in'
                && CountryCode != 'ee'
                && <p className="bold"> GOJO GLOBAL </p>
              }
              {
                (CountryCode == 'my')
                && <p className="bold"> GOJO ASIA </p>
              }
              {CountryCode == 'us' && <p className="bold"> {infoUS.name} </p>}
              {CountryCode == 'gb' && <p className="bold"> ARIYAH ELITE </p>}
              {
                (CountryCode == 'my' || CountryCode == 'th' || CountryCode == 'ph' || CountryCode == 'jo' || CountryCode == 'in' || CountryCode == 'sa' || CountryCode == 'ge' || CountryCode == 'id' || CountryCode == 'ee') &&
                <p className="bold">
                  {props.intl.formatMessage({ id: "footer.information.licensedBy" }, { br: <br /> })}
                </p>
              }
              {
                CountryCode == 'vn' && languageCode == 'vn-vi' && (
                  <>
                    <p><b>Công Ty TNHH Thương Mại Và Dịch Vụ Số OGIS</b> </p>
                    <p>300 Lý Triện, Phường An Khê, Quận Thanh Khê, Thành phố Đà Nẵng, Việt Nam</p>
                    <p><a href="tel:0905999184">0905 999 184</a> </p>
                    <p><a href="hotro@ogisdigital.com">hotro@ogisdigital.com</a> </p>
                    <p>Giấy phép kinh doanh số 0402026706, cấp ngày 14/02/2020 tại Sở Kế hoạch và Đầu tư, TP. Đà Nẵng</p>
                  </>
                )

              }
              {
                CountryCode == 'vn' && languageCode == 'vn-en' && (
                  <>
                    <p><b>OGIS Digital Service & Trading Company Limited</b> </p>
                    <p>300 Ly Trien, An Khe Ward, Thanh Khe District, Da Nang City, Vietnam</p>
                    <p><a href="tel:0905999184">0905 999 184</a> </p>
                    <p><a href="hotro@ogisdigital.com">hotro@ogisdigital.com</a> </p>
                    <p>Business License No. 0402026706, issued on February 14, 2020 at the Department of Planning and Investment, Da Nang city</p>
                  </>
                )
              }
              <p></p>
              <p>
                {/* {CountryCode == 'vn' && <><b>{props.intl.formatMessage({id: "footer.information.address_1"})}</b><br /></>} */}
                {
                  CountryCode != 'ge' && CountryCode != 'no' && CountryCode != 'us' && CountryCode != 'gb' && CountryCode != 'fr'
                  && CountryCode != 'vn'
                  && props.intl.formatMessage({ id: "footer.information.address" }, { br: <br /> })
                }
              </p>
              {
                CountryCode == 'no' && (
                  <div className="ft_left">
                    <p>Organisasjonsnr: <span>921342 691</span></p>
                    <p>Navn/foretaksnavn: <span>SMOOTH RIDESHARING AS</span></p>
                    <p>Forretningsadresse: <span>Kvitsøygata 1 5537 Haugesund, Norway</span></p>
                  </div>
                )
              }
              {
                CountryCode == 'gb' && (
                  <div className="ft_left">
                    <p>Address: <span>Citibase Gyleview House, 3 Redhughes Rigg, Suite 202a, Edinburgh, EH12 9DQ</span></p>
                    <p>Phone Number: <span> <a href="tel:0131 235 2785"> 0131 235 2785</a> </span></p>
                    <p>Email: <a href={`mailto:${email}`}>{email}</a></p>
                  </div>
                )
              }
              {
                CountryCode == 'ge' && (
                  <div className="ft_left">
                    <p>Address: <span>Tbilisi, Georgia</span></p>
                    <p>Phone Number: <span> <a href="tel:0322185454">032 218 54 54</a> </span></p>
                    <p>Email: <a href="mailto:support.ge@gojo.global">support.ge@gojo.global</a></p>
                  </div>
                )
              }
              {
                CountryCode == 'us' && (
                  <div className="ft_left">
                    {
                      Context.nameZoneUS == 'illinois' ? (
                        <p>Address: <span>Illinois, USA</span></p>
                      ) : (
                        <p>Address: <span>{infoUS.address}</span></p>
                      )
                    }
                    <p>Phone Number: <span> <a href={`tel:${infoUS.phone}`}> {infoUS.phone}</a> </span></p>
                    <p>Email: <a href={`mailto:${email}`}>{email}</a></p>
                  </div>
                )
              }
              {
                CountryCode !== 'ge' && CountryCode !== 'gb' && CountryCode !== 'us' && CountryCode !== 'fr' &&
                CountryCode !== 'vn' &&
                <>
                  <p className="emailP">
                    {props.intl.formatMessage({ id: "driver.formSignUp.email" })}: <a href={`mailto:${email}`}>{email}</a>
                  </p>
                  {
                    phone &&
                    <p className="phoneP">
                      <a href={`tell:${phone}`}>{phone}</a>
                    </p>
                  }
                </>
              }
              {
                CountryCode == 'fr' &&
                <p className="emailP">
                  <a href={`mailto:${email}`}>{email}</a>
                </p>
              }
              <div className="soical-container">
                {
                  CountryCode == 'us' &&
                  (
                    <>
                      <div>
                        <a href={infoUS.fb} target="_blank">
                          <div id="facebook"></div>
                        </a>
                      </div>

                      <div>
                        <a href={infoUS.ins} target="_blank">
                          <div id="instagram"></div>
                        </a>
                      </div>

                      <div >
                        <a href={infoUS.ytb} target="_blank">
                          <div id="youtube"></div>
                        </a>
                      </div>
                    </>
                  )
                }
                {
                  CountryCode != 'us' && SocialLinks[CountryCode] &&
                  (
                    <>
                      <div>
                        <a href={SocialLinks[CountryCode].fb} target="_blank">
                          <div id="facebook"></div>
                        </a>
                      </div>

                      <div>
                        <a href={SocialLinks[CountryCode].ins} target="_blank">
                          <div id="instagram"></div>
                        </a>
                      </div>

                      <div >
                        <a href={SocialLinks[CountryCode].ytb} target="_blank">
                          <div id="youtube"></div>
                        </a>
                      </div>
                    </>
                  )
                }
              </div>
              <SelectCountry />
            </div>
          </Col>

          <Col xs={12} md={8}>
            <Row className="footer row">
              <Col sm={CountryCode === 'my' ? 3 : 4}>
                <h5 className="footer name-col">
                  <FormattedMessage id="footer.company.title" />
                </h5>
                <p>
                  <Link className='footer item-col' activeClassName='footer active-item-col' to={`/${languageCode}/company`}>
                    <FormattedMessage id="footer.company.about" />
                  </Link>
                </p>
                <p>
                  <Link className='footer item-col' activeClassName='footer active-item-col' to={`/${languageCode}/terms`}>
                    <FormattedMessage id="footer.company.terms" />
                  </Link>
                </p>
              </Col>

              <Col sm={CountryCode === 'my' ? 3 : 4}>
                <h5 className="footer name-col">
                  <FormattedMessage id="footer.driver.title" />
                </h5>
                <p>
                  <Link className='footer item-col' activeClassName='footer active-item-col' to={`/${languageCode}/driver/#be_driver`}>
                    <FormattedMessage id="footer.driver.becomeDriver" />
                  </Link>
                </p>
                <p>
                  <Link className='footer item-col' activeClassName='footer active-item-col' to={`/${languageCode}/driver/#how_driver`}>
                    <FormattedMessage id="footer.driver.howWork" />
                  </Link>
                </p>
                <p>
                  <Link className='footer item-col' activeClassName='footer active-item-col' to={`/${languageCode}/driver/#safety_driver`}>
                    <FormattedMessage id="footer.driver.safety" />
                  </Link>
                </p>
                {/* <p>
                  <Link className='footer item-col' activeClassName='footer active-item-col' to='/driver/#safety_driver'>GOJO Driver Centre</Link>
                </p> */}
              </Col>

              <Col sm={CountryCode === 'my' ? 3 : 4}>
                <h5 className="footer name-col">
                  <FormattedMessage id="footer.passangers.title" />
                </h5>
                <p>
                  <Link className='footer item-col' activeClassName='footer active-item-col' to={`/${languageCode}/customer/#service`}>
                    <FormattedMessage id="footer.passangers.service" />
                  </Link>
                </p>
                <p>
                  <Link className='footer item-col' activeClassName='footer active-item-col' to={`/${languageCode}/customer/#enjoy-rider`}>
                    <FormattedMessage id="footer.passangers.why" />
                  </Link>
                </p>
                <p>
                  <Link className='footer item-col' activeClassName='footer active-item-col' to={`/${languageCode}/customer/#book-a-ride`}>
                    <FormattedMessage id="footer.passangers.bookARide" />
                  </Link>
                </p>
                <p>
                  <Link className='footer item-col' activeClassName='footer active-item-col' to={`/${languageCode}/customer/#ride-safety`}>
                    <FormattedMessage id="footer.passangers.safety" />
                  </Link>
                </p>
              </Col>

              {
                CountryCode === 'my' &&
                <Col sm={3}>
                  <h5 className="footer name-col">
                    <FormattedMessage id="footer.help.title" />
                  </h5>
                  <p>
                    <Link className='footer item-col' activeClassName='footer active-item-col' to='https://support.gojo.asia/driver/#/'>
                      <FormattedMessage id="footer.help.driver" />
                    </Link>
                  </p>
                  <p>
                    <Link className='footer item-col' activeClassName='footer active-item-col' to='https://support.gojo.asia/customer/#/'>
                      <FormattedMessage id="footer.help.passenger" />
                    </Link>
                  </p>
                </Col>
              }
            </Row>
          </Col>
        </Row>
      </div>
      <CookieConsent
        location="bottom"
        buttonText={'I understand'}
        cookieName="GOJOCookies"
        style={{ background: "#2B373B", zIndex: 10000000000000, justifyContent: "center" }}
        buttonId={"hCookie"}
        expires={150}
        contentClasses="hContentCookie"
        contentStyle={{ flex: "1 1 1" }}
      >
        {
          "This website uses cookies to enhance the user experience."
        }{" "}
        <span style={{ fontSize: "10px" }}>
        </span>
      </CookieConsent>
    </footer>
  )
}

export default injectIntl(Footer)