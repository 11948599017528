
import React from "react"
import { checkSupportCountry, filterLanguageSupportBasedCountry, getFleetIdFromCountryCode } from '../constants/commonFunctions'
import { PathNameLanguages, CountriesHasMultiFleet } from '../constants/commonData'
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const URL_CC_API = process.env.CC_API
const SERVER = process.env.SERVER || 'dev'
const BRANDID = process.env.BRANDID || ''
const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

const defaultState = {
  country: 'us',
  language: 'en',
  isLoading: true,
  fleetId: getFleetIdFromCountryCode('us'),
  nameZoneUS: 'Wisconsin',
  zoneUS: [],
  setFleetId: () => {},
  setCountry: () => {},
  setLanguage: () => {},
  setZoneUs: () => {},
  setNameZoneUS: () => {}
}

const CountryContext = React.createContext(defaultState)

class CountryProvider extends React.Component {
  state = {
    country: 'us',
    language: 'en',
    fleetId: getFleetIdFromCountryCode('us'),
    nameZoneUS: 'Wisconsin',
    zoneUS: [],
    fleetInfo: {}
  }

  changeCountry = (code) => {
    this.setState({ country: code })
  }

  changeLanguage = (code) => {
    this.setState({ language: code })
  }

  changeFleetId = (fleetId) => {
    if (fleetId) {
      fetch(`${URL_CC_API}/driver-sign-up/getFleetInfo?fleetId=${fleetId}`)
      .then(response => response.json())
      .then(dataJs => {
        const data = dataJs && dataJs.res || {}
        this.setState({
          fleetId: fleetId,
          fleetInfo: data
        })
      })
      .catch(function() {
      })
    }
  }

  changeNameZoneUS = (name) => {
    if (name) {
      this.setState({ nameZoneUS: name.toLowerCase() })
    }
  }

  changeZonesUs = (zones = []) => {
    if (zones.length > 0) {
      let zonesShorted = this.sortByName(zones)
      this.setState({ zoneUS: zonesShorted })
    }
  }

  sortByName = (zones = []) => {
     let ZonesSorted = zones.sort(function(a, b) {
      var nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
      var nameB = b.displayName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    });
    return ZonesSorted
  }


  getUserPosition = (countryCode = 'vn') => {
    countryCode = countryCode.toUpperCase()
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((postion) => {
        let {latitude, longitude} = postion.coords || {}
        console.log(postion)
        if(latitude && longitude) {
          // fetch(`http://0.0.0.0:9090/api/provider-sign-up/find-globalZone-with-point?geo[]=${longitude}&geo[]=${latitude}&brandId=5fbf614560b25a0efde2329b`)
          // fetch(`${URL_CC_API}/provider-sign-up/find-globalZone-with-point?geo[]=${-98.840138}&geo[]=${32.212801}&brandId=${BRANDID}&countryCode=${countryCode}`)
          // Lấy fleetId theo vị trí user
          fetch(`${URL_CC_API}/provider-sign-up/find-globalZone-with-point?geo[]=${longitude}&geo[]=${latitude}&brandId=${BRANDID}&countryCode=${countryCode}`)
          .then(response => {
            return response.json();
          })
          .then(json => {
            if(json.res && json.res.length > 0) {
              let {fleetId, displayName} = json.res[0]
              this.changeFleetId(fleetId)
              this.changeNameZoneUS(displayName)
            }
          })
          .catch(error => {
          })
        }
      }, () => {}, options);
    } else {
      alert("Geolocation is not supported by this browser.")
    }
  }

  componentDidMount = () => {
    let self = this
    let URLObj = new URL(window.location.href)
    let pathname = URLObj && URLObj.pathname
    // Nếu trong URL location có sẵn country + language 
    if(pathname && pathname.length >= 6 && PathNameLanguages.indexOf(pathname.slice(1, 6)) >= 0) {
      const countryCode = pathname.slice(1, 3)
      this.setState({country: countryCode, isLoading: false})
      changeLocale(pathname.slice(1, 6))
      const fleetId = getFleetIdFromCountryCode(countryCode)
      this.changeFleetId(fleetId)
    } else {
      // Detect country based IP
      fetch('https://api.ipdata.co?api-key=24a554b92d414bf96f17e3d051193479e6151058c4e8b73fd5dba2a0')
      .then(response => response.json())
      .then(data => {
        if(data.country_code) {
          const countryCodeDetected = data.country_code.toLowerCase()
          let countryCode = 'us'
          let language = `us-en`
          if(checkSupportCountry(countryCodeDetected)) {
            //country được website support ?
            if(CountriesHasMultiFleet.indexOf(countryCodeDetected) >= 0) {
              //Nếu country có  multi fleet thì đi tìm fleetId theo location?
              this.getUserPosition(countryCodeDetected)
            }
            countryCode = countryCodeDetected
            const userLanguage = filterLanguageSupportBasedCountry(countryCode)
            const fleetId = getFleetIdFromCountryCode(countryCode)
            this.changeFleetId(fleetId)
            language = `${countryCode}-${userLanguage}`
          }
          this.setState({country: countryCode, isLoading: false})
          changeLocale(language)
        }
      })
      .catch(function() {
        self.setState({isLoading: false})
      })
    }

    // Get all zones of brandId
    fetch(`${URL_CC_API}/provider-sign-up/find-globalZones?brandId=${BRANDID}`)
    .then(response => {
      return response.json();
    })
    .then(json => {
      if(json.res && json.res.list.length > 0) {
        let zones = json.res.list
        let zoneUS = zones.filter((zone) => ( zone.countryCode ==  'US'))
        this.changeZonesUs(zoneUS)
      }
    })
    .catch(error => {
    })
  }

  render() {
    const { children } = this.props
    return (
      <CountryContext.Provider
        value={{
          country: this.state.country,
          setCountry: this.changeCountry,
          language: this.state.language,
          setLanguage: this.changeLanguage,
          fleetId: this.state.fleetId,
          setFleetId: this.changeFleetId,
          zoneUS: this.state.zoneUS,
          changeZonesUs: this.changeZonesUs,
          nameZoneUS: this.state.nameZoneUS,
          setNameZoneUS: this.changeNameZoneUS,
          fleetInfo: this.state.fleetInfo
        }}
      >
        {children}
      </CountryContext.Provider>
    )
  }
}
export default CountryContext
export { CountryProvider }
