import React, { useState } from "react";
import LocalizedLink from "../LocalizedLink/localizedLink"

import { navigationsData } from '../../utils/hummingbird-nav';
import HambOpenWhite from './../../assets/images/hamb-open-white.svg';
import HambCloseWhite from './../../assets/images/hamb-close-white.svg';
import HambOpen from './../../assets/images/hamb-open.svg';
import HambClose from './../../assets/images/hamb-close.svg';
import './Navigation.scss';
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

const Navigation = (props) => {
  const { open = false, handleClickOnMenu,  isTop, intl, countryCode} = props || {};
  let HambOpenBtn = isTop ? HambOpenWhite : HambOpen
  let HambCloseBtn = isTop ? HambCloseWhite : HambClose

  const renderNavItems = (navData, isSub) => {
    if (!navData || !navData.length) return null;
    return (
      <ul className={`hummingbird-nav__main`}>
        {
          navData.map((navItem, index) => {
            let { key, label, path, child, enable, header } = navItem || {};
            path = `/${props.languageCode}/${path}`
            if (!key || !enable || !header || (countryCode == 'vn' && navItem.key == 'founderFund')) return null;
            return (
              <li
                className="hummingbird-nav__item"
                aria-label={label}
                key={index}
              >
                <LocalizedLink
                  to={isSub ? path : "/"}
                  partiallyActive={!isSub ? true : false}
                  activeClassName="active"
                  onClick={(e) => {
                    if (!isSub) e.preventDefault();
                    else handleClickOnMenu(false);
                  }}
                >
                  <FormattedMessage id={label} />
                </LocalizedLink>
                {
                  child ? renderNavItems(child, true) : null
                }
              </li>
            )
          })
        }
        {
          countryCode == 'my' &&
          <li
            className="hummingbird-nav__item"
            aria-label="newsroom"
            key="newsroom"
          >
          <LocalizedLink
              to={`/${props.languageCode}/blog-ms`}
              partiallyActive={!isSub ? true : false}
              activeClassName="active"
              onClick={(e) => {
                if (!isSub) e.preventDefault();
                else handleClickOnMenu(false);
              }}
            >
              {intl.formatMessage({ id: 'header.newsrooms' })}
            </LocalizedLink>
          </li>
        }
      </ul>
    )
  }

  return <nav>
    <button
      className="hummingbird-ham"
      onClick={() => handleClickOnMenu(!open)}
    >
      {
        open ? <HambCloseBtn
          className="hummingbird-ham__icon opened"
        /> : <HambOpenBtn
            className="hummingbird-ham__icon"
          />
      }
    </button>
    <div className={`hummingbird-nav${open ? ' opened' : ''}`}>
      {
        renderNavItems(navigationsData || [], true)
      }
    </div>
    {/* <LocalizedLink to="/" aria-label={backToHome}>{homeTitle}</LocalizedLink> */}
  </nav>
}

export default injectIntl(Navigation)

