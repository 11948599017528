import {LanguageBasedCountry, CountriesCustome, SupportEmails, listHomeBannerUS} from './commonData'
const SERVER = process.env.SERVER || 'dev'

export const detectBrowserLanguage = () => {
  let browserLanguage = navigator && navigator.language || 'en'
  let codeBrowserLanguage = browserLanguage.slice(0, 2).toLowerCase()
  return codeBrowserLanguage
}

export const filterLanguageSupportBasedCountry = (countryCode = 'my') => {
  if(!checkSupportCountry(countryCode)) return 'en'
  const listLanguage = LanguageBasedCountry[countryCode] 
  const browserLanguage = detectBrowserLanguage()

  // ưu tiên nếu đã có local storage
  const languageStorage = localStorage.getItem('language-Selected');
  if (languageStorage) {
    if (listLanguage.indexOf(languageStorage) >= 0) return languageStorage
  }

  // lấy theo setting trên browser
  if (listLanguage.indexOf(browserLanguage) >= 0) return browserLanguage

  // lấy theo default 
  if(countryCode === 'my') return 'ms'
  if(countryCode === 'vn') return 'vi'
  if(countryCode === 'fr') return 'fr'
  if(countryCode === 'no') return 'no'
  if(countryCode === 'us') return 'en'
  if(countryCode === 'gb') return 'en'
  if(countryCode === 'th') return 'th'
  if(countryCode === 'ph') return 'en'
  if(countryCode === 'jo') return 'ar'
  if(countryCode === 'sa') return 'ar'
  if(countryCode === 'ge') return 'en'
  if(countryCode === 'pk') return 'en'
  if(countryCode === 'id') return 'en'
  if(countryCode === 'ee') return 'en'
}

export const checkSupportCountry = (countryCode = 'us') => {
  let country = CountriesCustome.find((country) => country.code == countryCode)
  if(country) return true
  return false
}

export const getEmail = (country, fleetId) => {
  if(country != 'us') return SupportEmails[country] || ''
  if(country == 'us') {
    if (fleetId == 'gojotexas') return "support.tx@gojo.global"
    return 'support.wi@gojo.global'
  }
}

export const getFleetIdFromCountryCode = (countryCode = '') => {
  let country = CountriesCustome.find((country) => country.code == countryCode)
  if(country) return country.fleetId && country.fleetId[SERVER]
  return 'us'
}

export const getHomeBannerUS = (nameZone = 'wisconsin') => {
  return listHomeBannerUS[nameZone] ? listHomeBannerUS[nameZone] : listHomeBannerUS.wisconsin 
 }