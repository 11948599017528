import React from "react"
import { Link } from "gatsby"
import { HummingDataContext } from "../../layouts"
import locales from "../../../config/i18n"

const extURL = (myURL) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + //port
    '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i');
  return pattern.test(myURL);
}

// Use the globally available context to choose the right path
const LocalizedLink = ({ to, ...props }) => {
  // const { locale } = React.useContext(HummingDataContext)

  // const isIndex = to === `/`
  // // If it's the default language, don't do anything
  // // If it's another language, add the "path"
  // // However, if the homepage/index page is linked don't add the "to"
  // // Because otherwise this would add a trailing slash

  // if (extURL(to)) {
  //   return <a {...props} target="_blank" href={to} >
  //     {
  //       props.children || ""
  //     }
  //   </a>
  // }

  // const path = locales[locale].default
  //   ? to
  //   : `${locales[locale].path}${isIndex ? `` : `${to}`}`

  return <Link {...props} to={to} activeClassName="active-nav-item" />
}

export default LocalizedLink
