import React, { Component } from "react"
import { MDXProvider } from "@mdx-js/react"
import MdxLink from "../components/MdxLink/MdxLink"
import { useStaticQuery, graphql } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import './../assets/styles/commonStyle.scss';

import Header from "../containers/Header/Header"
import Footer from "../containers/Footer/Footer"


const signUpFormData = {
  fullName: '',
  companyName: '',
  phone: '',
  workEmail: '',
  companyUrl: '',
  countryCode: '',
  country: '',
  typeOfOrg: '',
  typeOfOrgName: '',
  numberOfDriver: '',
  message: ''
}

const defaultContextValue = {
  country: 'us',
  setCountry: () => {}
}

export const HummingDataContext = React.createContext()
export const { Provider, Consumer: ContextConSumer } = HummingDataContext;

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!

class Layout extends Component {
  constructor() {
    super()
    this.state = {
      ...defaultContextValue,
      setCountry: this.setCountry,
    }
  }

  componentDidMount() {
    let pathname = this.props.location.pathname || ""
    if(pathname.indexOf("/rider") > -1) {
      window.location.replace(this.props.location.href.replace('rider', 'driver'));
    }
  }

  setCountry = (code) => {
    this.setState({ country: code || 'en' })
  }

  render() {
    const { children, pageContext, location } = this.props || {};
    const { locale = 'en' } = pageContext || {};
    const value = {
      ...this.state,
      locale,
      location
    }
    let pathname = location.pathname || ""
    let isCalculatorPage = pathname.indexOf("/founder's-fund-calculator") > -1 ? true : false
    let isDriverPage = pathname.indexOf("/driver") > -1 ? true : false
    return (
      <MDXProvider components={{ a: MdxLink }}>
        {!isCalculatorPage && <Header isDriverPage={isDriverPage} pathname={pathname}/>}
        <main>{children}</main>
        <Footer />
      </MDXProvider>
    )
  }
}

export default ({ children, pageContext, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          description,
          author
        }
      }
    }
  `)
  return <Layout
    data={data}
    children={children}
    pageContext={pageContext}
    location={location}
  />
}