import React, { Component } from 'react';

import { debounce } from './../../utils/utils';

import Navigation from '../../components/Navigation/Navigation';
import LocalizedLink from './../../components/LocalizedLink/localizedLink';
import ButtonScroll from '../../components/ButtonScroll'
import { navigate } from "gatsby"
import { changeLocale } from "gatsby-plugin-intl"
import { Dropdown } from 'react-bootstrap';
import CountryContext from "../../context/CountryContext"
import WorldIcon from './../../assets/images/world.svg'
import { injectIntl } from "gatsby-plugin-intl"
import { LanguageBasedCountry, LanguageName } from '../../constants/commonData'
import SelectCountry from '../../components/SelectCountry/SelectCountry'
import { FormattedMessage } from "gatsby-plugin-intl"
import { ChevronDown, ChevronUp, X } from 'react-bootstrap-icons';
import './header.scss';
class Header extends Component {
	static contextType = CountryContext
	constructor(props) {
		super(props);
		let showSelectCountry = false
		if (typeof window !== 'undefined') {
			showSelectCountry = localStorage.getItem('showSelectCountry') ? false : true
		}
		this.state = {
			open: false,
			isTop: true,
			countryState: '',
			languageState: '',
			fleetId: '',
			displayName: 'wisconsin',
			showSelectCountry: showSelectCountry
		}

		this.handleCheckTopHeaderDebounce = debounce(this.handleCheckTopHeader);
		this.handleClickOutsideDebounce = debounce(this.handleClickOutside);
	}


	handleCheckTopHeader = () => {
		const isTop = (window.pageYOffset || 0) < 100;
		if (isTop !== this.state.isTop) {
			this.setState({
				isTop
			})
		}
	}

	handleClickOutside = (event) => {
		if (this.headerRef && !this.headerRef.contains(event.target) && this.state.open) {
			this.setState({
				open: false
			})
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleCheckTopHeaderDebounce);
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleCheckTopHeaderDebounce);
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOnMenu = (open) => {
		this.setState({
			open
		})
	}

	changeLanguage = (language = 'en', country = 'my') => {
		localStorage.setItem("language-Selected", language);
		let languageByCountry = `${country}-${language}`
		this.context.setLanguage(language)
		changeLocale(languageByCountry)
	}

	changeCountryLocal = (countryCode = '', userLanguage = '', fleetId = '', displayName = 'wisconsin') => {
		this.setState({
			countryState: countryCode,
			languageState: userLanguage,
			fleetId: fleetId,
			displayName: displayName
		})
	}

	confirmChangeCountry = () => {
		let { countryState, languageState, fleetId, displayName } = this.state
		this.setState({ showSelectCountry: false })
		if (countryState && languageState && fleetId) {
			if (countryState == 'gb') {
        window.location.href = 'https://ridegojo.com/?c=scotland';
			} else {
				let languageByCountry = `/${countryState}-${languageState}/`
				this.context.setLanguage(languageState)
				this.context.setCountry(countryState)
				this.context.setFleetId(fleetId)
				this.context.setNameZoneUS(displayName)
				navigate(languageByCountry)
			}
		}
	}

	closeSelectCountry = () => {
		this.setState({ showSelectCountry: false })
		if (typeof window !== 'undefined') {
			localStorage.setItem("showSelectCountry", true);
		}
	}

	render() {
		const {
			open = false,
			isTop = true,
			showSelectCountry
		} = this.state || {};
		const { intl } = this.props
		const languageCode = intl.locale || "en"
		const currentCountry = this.context && this.context.country || 'my'
		const languagesSupported = LanguageBasedCountry[currentCountry]
		return (
			<CountryContext.Consumer>
				{theme => (
					<header
						ref={(node) => this.headerRef = node}
						className={`global-header hummingbird-header ${isTop ? '' : 'header-shadow'}`}
					>
						<div>
							{
								showSelectCountry &&
								(
									<div className="country_selection">
										<p><FormattedMessage id="header.specificCountry" /></p>
										<div className="group-btn">
											<SelectCountry
												hasContinue
												changeCountryLocal={this.changeCountryLocal}
												countryState={this.state.countryState}
												languageState={this.state.languageState}
											/>
											<button className="btn_continue" onClick={this.confirmChangeCountry}>
												<FormattedMessage id="header.continue" />
											</button>
											<X className="svg-close" onClick={this.closeSelectCountry} />
										</div>
									</div>
								)
							}
						</div>
						<div className="header-line-shadow"></div>
						<div className="h-container">
							<div className="hummingbird-header__leftgrp">
								<LocalizedLink
									to={`/${languageCode}/`}
									className="hummingbird-header__logo"
									onClick={() => this.handleClickOnMenu(false)}
								>
									{
										(theme.country != 'my' && theme.country != 'in') &&
										<img
											className="logoBanner_2"
											src={`../../../images/logo_2.png`}
										/>
									}
									{
										(theme.country == 'my' || theme.country == 'in') &&
										<img
											className="logoBanner"
											src={`../../../images/logo.png`}
										/>
									}
								</LocalizedLink>
							</div>

							<div className="hummingbird-header__rightgrp">
								<div className="sub_right">
									<ButtonScroll
										isDriverPage={this.props.isDriverPage}
										keyWord={this.props.intl.formatMessage({ id: "button.driverSignup" }, { br: <br /> })}
										countryCode={theme.country}
										languageCode={languageCode}
									/>
									<Dropdown
										id="switch-lang-dropdown"
										className="display-flex align-items-center"
									>
										<Dropdown.Toggle className="btn_language">
											{languageCode && languageCode.toUpperCase() && languageCode.toUpperCase().split('-')[1]}
											{<WorldIcon className={"icon_language " + (isTop ? "" : "scroll")} />}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{
												languagesSupported.map(language => (
													<Dropdown.Item
														eventKey={LanguageName[language]}
														key={LanguageName[language]}
														onClick={() => this.changeLanguage(language, theme.country)}
													>
														<span>{LanguageName[language]}</span>
													</Dropdown.Item>
												))
											}
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<Navigation
									open={open}
									handleClickOnMenu={this.handleClickOnMenu}
									isTop={isTop}
									languageCode={languageCode}
									countryCode={theme.country}
								/>
							</div>
						</div>
					</header>
				)}
			</CountryContext.Consumer>
		)
	}
}

export default injectIntl(Header)