import React, { Component } from 'react';
import HButtonGradient from '../HButton/HButtonGradient'
import Media from 'react-media';
import link from '../../../config/link';
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import './buttonScroll.scss'

class ButtonCustom extends Component {
	render() {
    let {isDriverPage, keyWord, className, intl} = this.props
		const languageCode = intl.locale || "en"
    let classVisibility = isDriverPage ? "hidden" : ""
    let pathMobile = `/${languageCode}${link.driverSignupMobile}`
    let pathDesktop = `/${languageCode}${link.driverSignupDesktop}`
		return (
      <>
        {
          (classVisibility == "" ?
            <>
              <div className="BtSmallScreen">
                <HButtonGradient
                  htmlType="link"
                  href={pathMobile}
                  keyWord={keyWord}
                  className={`hummingbird-header__signUpBtn ${classVisibility} ${className}`}
                />
              </div>
              <div className="BtBigScreen">
                <HButtonGradient
                  htmlType="link"
                  href={pathDesktop}
                  keyWord={keyWord}
                  className={`hummingbird-header__signUpBtn ${classVisibility} ${className}`}
                />
              </div>
            </>
          :
            <></>
          )
        }
      </>
		)
	}
}

export default injectIntl(ButtonCustom)