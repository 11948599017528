import React, { useContext, useState, Fragment } from 'react';
import { Dropdown, Accordion, Card } from 'react-bootstrap'
import { navigate } from "gatsby"
import { CountriesCustome, CountryName } from '../../constants/commonData'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import CountryContext from "../../context/CountryContext"
import FlagIconFactory from 'react-flag-icon-css';
import { ChevronDown, ChevronUp, ChevronRight } from 'react-bootstrap-icons';
import { filterLanguageSupportBasedCountry } from '../../constants/commonFunctions'
// import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './SelectCountry.scss';
const SERVER = process.env.SERVER || 'dev'


const FlagIcon = FlagIconFactory(React, { useCssModules: false });
function Footer({ hasContinue = false, changeCountryLocal }) {
  const Context = useContext(CountryContext);
  const [countryState, setCountryState] = useState('')
  const [classMenuChild, setClassMenuChild] = useState('')
  const toggleChildMenu = () => {
    if (classMenuChild) return setClassMenuChild('')
    return setClassMenuChild('opensubBot')
  }
  const resetChildMenu = () => {
    setClassMenuChild('')
  }
  const selectCountry = (country, fleetId, displayName) => {
    const userLanguage = filterLanguageSupportBasedCountry(country)
    let language = `/${country}-${userLanguage}/`
    // trường hợp selectCountry ở footer
    if (!hasContinue) {
      if (country == 'gb') {
        window.location.href = 'https://ridegojo.com/?c=scotland';
      } else {
        Context.setCountry(country)
        navigate(language)
        Context.setLanguage(userLanguage)
        Context.setFleetId(fleetId)
        Context.setNameZoneUS(displayName)
      }
    }

    // trường hợp selectCountry ở header
    if (hasContinue) {
      changeCountryLocal(country, userLanguage, fleetId, displayName)
      setCountryState(country)
    }
  }

  let lastCountry = (hasContinue && countryState) ? countryState : Context.country
  return (
    <div>
      <Dropdown
        id="switch-country"
        className="display-flex align-items-center"
        onToggle={() => resetChildMenu()}
      >
        <Dropdown.Toggle className="btn_language">
          <div className='select_country'>
            <div>
              <FlagIcon code={lastCountry == 'gb' ? 'gb-sct' : lastCountry} />
              <span className="name_top">{CountryName[lastCountry]}</span>
            </div>
            <div>
              <ChevronDown className="ChevronUp" />
              <ChevronUp className="ChevronDown" />
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu onSelect={() => resetChildMenu()}>
          {
            CountriesCustome.map((countryObj) => {
              let { code: country, fleetId } = countryObj
              if (country == 'us') {
                return (
                  <div className='countryMulti'>
                    {/* <Dropdown.Divider /> */}
                    <span className='subMenu'
                      onClick={() => toggleChildMenu()}
                    >
                      <FlagIcon code={country == 'gb' ? 'gb-sct' : country} />
                      {CountryName[country]}
                      {classMenuChild ? <ChevronDown /> : <ChevronRight />}
                    </span>
                    <div className={`subMenuBot ${classMenuChild}`}>
                      {
                        Context.zoneUS && Context.zoneUS.map((zone) => {
                          return (
                            <Dropdown.Item key={zone.displayName} onClick={() => selectCountry(country, zone.fleetId, zone.displayName)}>
                              <span className='namefleet'>
                                <img src='../../../images/point.png  ' /> {zone.displayName}
                              </span>
                            </Dropdown.Item>
                          )
                        })
                      }
                    </div>
                    {/* <Dropdown.Divider /> */}
                  </div>
                )
              }
              return (
                <>
                  {/* <Dropdown.Divider /> */}
                  <Dropdown.Item
                    key={country}
                    onClick={() => selectCountry(country, fleetId[SERVER])}
                  >
                    <span><FlagIcon code={country == 'gb' ? 'gb-sct' : country} /> {CountryName[country]}</span>
                  </Dropdown.Item>
                </>
              )
            })
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default injectIntl(Footer)