import { useState, useEffect } from 'react';

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, wait = 50, immediate) => {
    var timeout;

    return function executedFunction() {
        var context = this;
        var args = arguments;

        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        var callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};

export function useWindowSize(debounceTimer) {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        const handleResize = () => {
            setWindowSize(getSize());
        }

        const handleResizeDebounce = debounce(handleResize, debounceTimer);

        window.addEventListener('resize', handleResizeDebounce);
        return () => window.removeEventListener('resize', handleResizeDebounce);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

export function SmoothVerticalScrolling(e, time, where) {
    let eTop = e.getBoundingClientRect().top;
    let eAmt = eTop / 1000;
    let curTime = 0;
    while (curTime <= time) {
        window.setTimeout(SVS_B, curTime, eAmt, where);
        curTime += time / 1000;
    }
}

function SVS_B(eAmt, where) {
    if (where == "center" || where == "")
        window.scrollBy(0, eAmt / 2);
    if (where == "top")
        window.scrollBy(0, eAmt);
}

const phoneNumberValidation = (inputtxt) => {
    const phoneno = /^\+?[0-9]*$/;
    const status = inputtxt.match(phoneno) ? true : false;
    return {
        status,
        message: status ? '' : 'phoneInvalid'
    }
}

const emailValidation = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const status = re.test(email);
    return {
        status,
        message: status ? '' : 'emailInvalid'
    }
}

export const validation = {
    isRequired: (value) => {
        if (!value || !value.length) return {
            status: false,
            message: 'isRequired'
        }
        return {
            status: true,
            message: ''
        }
    },
    phoneNumberValidation,
    emailValidation
}