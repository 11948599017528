export const navigationsData = [
    {
        key: 'solutions',
        label: 'header.company',
        path: 'company/',
        enable: true,
        header: true,
        footer: true,
        isSub: false,
    },
    {
        key: 'Driver',
        label: 'header.driver',
        path: 'driver/',
        enable: true,
        header: true,
        footer: true,
        isSub: false,
    },
    {
      key: 'founderFund',
      label: "header.founderFund",
      path: "founder's-fund/",
      enable: true,
      header: true,
      footer: true,
      isSub: false,
  },
    {
        key: 'passengers',
        label: 'header.customer',
        path: 'customer/',
        enable: true,
        header: true,
        footer: true,
        isSub: false,
    }
]