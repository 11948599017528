// Only one item MUST have the "default: true" key

module.exports = {
  en: {
    default: true,
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Using i18n with Gatsby`,
    defaultDescription: `Gatsby example site using MDX and dependency-free i18n`,
  },
  vi: {
    path: `vi`,
    locale: `vi-VN`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `vi`,
    ogLanguage: `vi_VN`,
    defaultTitle: `Sử dụng i18n với Gatsby`,
    defaultDescription: `Trang mẫu Gatsby sử dụng MDX và i18n (không phụ thuộc plugin)`,
  },
  th: {
    path: `th`,
    locale: `th-TH`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `th`,
    ogLanguage: `th_TH`,
    defaultTitle: `Sử dụng i18n với Gatsby`,
    defaultTitle: `Using i18n with Gatsby`,
    defaultDescription: `Gatsby example site using MDX and dependency-free i18n`,
  },
  ph: {
    path: `ph`,
    locale: `ph-PH`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `ph`,
    ogLanguage: `pn_EN`,
    defaultTitle: `Sử dụng i18n với Gatsby`,
    defaultTitle: `Using i18n with Gatsby`,
    defaultDescription: `Gatsby example site using MDX and dependency-free i18n`,
  },
  ar: {
    path: `ar`,
    locale: `ar-JO`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `ar`,
    ogLanguage: `ar_EN`,
    defaultTitle: `Sử dụng i18n với Gatsby`,
    defaultTitle: `Using i18n with Gatsby`,
    defaultDescription: `Gatsby example site using MDX and dependency-free i18n`,
  },
  ee: {
    path: `ee`,
    locale: `ee-EN`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Sử dụng i18n với Gatsby`,
    defaultTitle: `Using i18n with Gatsby`,
    defaultDescription: `Gatsby example site using MDX and dependency-free i18n`,
  }
}
