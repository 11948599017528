// Only one item MUST have the "default: true" key

module.exports = {
  // driverSignup: "https://driversignup.gojo.asia/?fleetToken=lcsb8etx&appName=gojosg_d",
  driverSignupDesktop: "/driver/#mBaner_driver",
  driverSignupMobile: "/driver/#toSrcroll",
  // driverSignup: "/driver/#anchor",
  appStore: "https://apps.apple.com/us/app/gojo-the-app-for-modern-riders/id1455304256",
  googlePlay: "https://play.google.com/store/apps/details?id=com.gojomalaysia.pax&hl=en",
  driverAppStore: "https://apps.apple.com/us/app/id1455304266",
  driverGooglePlay: "https://play.google.com/store/apps/details?id=com.gojomalaysia.driver",
  paxHuawei: "https://appgallery.huawei.com/app/C105690681",
  driverHuawei: "https://appgallery.huawei.com/app/C105693517",
  driverAppStoreJordan: "https://rlink.app/global_app/thpjh52k/driver",
  appStoreJordan: "https://book.goodjourney.io/?f=thpjh52k",
}
